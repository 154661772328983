<template>
  <v-dialog v-model="dialog" scrollable :fullscreen="$app.isMobile">
    <v-card>
      <v-card-title class="headline">Movimentação do Produto</v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <app-card-dashboard
              title="Vendas"
              icon=" mdi-cart"
              iconColor="success"
            >
              <template v-slot:text>
                <h2>
                  {{ $format.decimal(salesTotal) }}
                </h2>
              </template>
            </app-card-dashboard>
          </v-col>
          <v-col>
            <app-card-dashboard
              title="Compras"
              icon=" mdi-dolly"
              iconColor="error"
            >
              <template v-slot:text>
                <h2>
                  {{ $format.decimal(purchaseTotal) }}
                </h2>
              </template>
            </app-card-dashboard>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-date-field
              @input="select()"
              v-model="searchParams.start_at"
              label="Data Inicial"
            />
          </v-col>
          <v-col>
            <app-date-field
              @input="select()"
              v-model="searchParams.end_at"
              label="Data Final"
            />
          </v-col>
          <v-col>
            <app-select
              @input="select()"
              v-model="searchParams.dimension"
              :items="dimensions"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-charts :options="options" :series="series" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text color="secondary"> Voltar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dimensions: [
        { value: "day", text: "Dia" },
        { value: "month", text: "Mês" },
      ],
      report: { purchase_report: [], sales_report: [] },
      dialog: false,
      product_id: null,
      searchParams: {},
      month: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      day: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
  },

  computed: {
    salesTotal() {
      return this.$calc.sum(this.report.sales_report, "quantity_sum");
    },
    purchaseTotal() {
      return this.$calc.sum(this.report.purchase_report, "quantity_sum");
    },

    options() {
      return {
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          categories:
            this.report.sales_report.map(
              (item) => item[this.searchParams.dimension]
            ) || [],
        },
      };
    },
    series() {
      return [
        {
          name: "Vendas",
          data: this.report.sales_report.map((item) => item.quantity_sum) || [],
        },
        {
          name: "Compras",
          data:
            this.report.purchase_report.map((item) => item.quantity_sum) || [],
        },
      ];
    },
  },

  methods: {
    open(product_id) {
      this.searchParams = {
        start_at: this.day,
        end_at: this.day,
        dimension: "day",
        product_id: product_id,
      };
      this.select();
      this.dialog = true;
    },

    select() {
      this.$http
        .index("product/product-movement", this.searchParams)
        .then((response) => {
          this.report = response;
        });
    },
  },
};
</script>

<style>
</style>